import React from 'react'
import { Button } from "antd";

// @ts-ignore
import appstoreIcon from '../../../assets/appstore.svg'

export default () => (
    <a href="https://itunes.apple.com/no/app/oss/id1355667096?mt=8" target="_blank" rel="noopener">
        <Button style={{ padding: 0 }} type="default" size="large" {...{ "aria-label": "Last ned fra app store" }}>
            <img alt="App store ikon" src={appstoreIcon} height="auto" width="130px" />
        </Button>
    </a>
)