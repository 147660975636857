import React from "react";
import { Input, Form, Icon, Button, Spin, Row, Col, Checkbox } from "antd";
import { Helmet } from "react-helmet";
import SiteLayout from "../components/SiteLayout";
import axios from "axios";
import { StaticQuery, graphql } from "gatsby";
import AppStoreButton from "../components/Buttons/AppStoreButton";
import GooglePlayButton from "../components/Buttons/GooglePlayButton";
import { loadStripe } from "@stripe/stripe-js";
// @ts-ignore
import styles from "./abonnement.module.scss";
import { FaBlackberry } from "react-icons/fa";

enum Page {
  Loading = 0,
  Purchase = 1,
  ThankYou = 2,
  Error = 3,
  ErrorUnkown = 4,
}

enum FormFields {
  FirstName = "firstName",
  LastName = "lastName",
  Email = "email",
  PhoneNumber = "phonenumber",
  Street = "streetAddress",
  PostCode = "postCode",
  PostalArea = "postalArea",
  Quantity = "quantity",
  Consent = "consent",
}

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const API_URL = process.env.GATSBY_APP_API_URL;
const API_TOKEN = process.env.GATSBY_APP_API_TOKEN;
const STRIPE_API_KEY = process.env.GATSBY_STRIPE_API_KEY;

const stripePromise = loadStripe(STRIPE_API_KEY);

const getHasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
};

class SubscriptionForm extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      page: Page.Loading,
      errorResponse: null,
      buttonLoading: false,
      subscriptionList: null,
      chosenSubscriptionId: null,
      accountId: null,
      meterId: null
    };
  }

  componentDidMount() {

    //TODO: url param user
    const urlParams = new URLSearchParams(window.location.search);
    const accountId = urlParams.get("account");
    const meterId = urlParams.get("meter");
    


    //get subscriptions
    var config = {
      headers: { Authorization: "bearer " + API_TOKEN },
    };

    axios
      .get(API_URL + `/api/purchase/subscriptions?accountId=${accountId}`, config)
      .then(async (res) => {
        if (res.status >= 200 && res.status < 300) {

          //TODO:set state
          const data = res.data;
          const subscriptionList = data.map(
            (element) => {
              return (
                <div onClick={() => this.handleSubscriptionSelect(element.id)} style={{ backgroundColor: "green" }}>
                  <h2>{element.name}</h2>
                  <h3>{element.costKr} ,- kr</h3>
                  {element.features.map(
                    (feature) => {
                      return (
                        <div>
                        <h3>{feature.name}</h3>
                        <h4>{feature.description}</h4>
                        </div>
                      )
                    }
                  )}
                </div>
              )
            }
          );


          this.setState({ subscriptionList: subscriptionList, accountId: accountId, meterId: meterId });
          this.setState({ buttonLoading: false, page: Page.Purchase });


        } else {
          this.setState({ page: Page.ErrorUnkown });
        }
      })
      .catch((err) => {
        this.setState({
          page: Page.Error,
          errorResponse: err.response.data,
          buttonLoading: false,
        });
      });
  };

  handleSubscriptionSelect(element) {
    this.setState({ chosenSubscriptionId: element });
    console.log("hei dette er handle sub " + element);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    
    this.setState({ buttonLoading: true });

    var config = {
      headers: { Authorization: "bearer " + API_TOKEN },
    };


    console.log("handlesubmit " + this.state.chosenSubscriptionId);
    var body = {
      SubscriptionId: this.state.chosenSubscriptionId,
      AccountId: this.state.accountId,
      MeterId: this.state.meterId
    };

    axios
      .post(API_URL + "/api/purchase/startsession/sub", body, config)
      .then(async (res) => {
        if (res.status >= 200 && res.status < 300) {
          const sessionId = res.data;
          const stripe = await stripePromise;
          await stripe.redirectToCheckout({ sessionId });
          this.setState({ buttonLoading: false });
        } else {
          this.setState({ page: Page.ErrorUnkown });
        }
      })
      .catch((err) => {
        this.setState({
          page: Page.Error,
          errorResponse: err.response.data,
          buttonLoading: false,
        });
      });
  };

  renderPurchasePage() {
    const { getFieldDecorator } = this.props.form;

    return (
      <StaticQuery
        query={graphql`
          {
            pitch: file(relativePath: { eq: "homescreen.png" }) {
              childImageSharp {
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        `}
        render={() => (
          <div style={{ textAlign: "center" }}>
            <h2 style={{ textAlign: "center" }}>Velg Abonnement</h2>
            <div className={styles.introsubtext}>
              Her står det sikkert info om Pris og betaling
              <p style={{ fontSize: "80%" }}>
                Vi starter å trekke månedsabonnementet ditt 30 dager etter
                bestilling. 
              </p>
            </div>

            <div className={styles.introsubtext}>
              <i>
                Vennligst velg 1 abonnement for din måler. Print meternumber?
              </i>
            </div>
            <div className={styles.introsubtext}>
              <p style={{ fontSize: "80%" }}>
                Bestilling gjelder kun privatpersoner. Bedrifter kan kontakte
                oss på hei@oss.no for å motta mer informasjon og tilbud på våre
                tjenester.{" "}
              </p>
            </div>
            <div className={styles.introtext}>
              <Form
                hideRequiredMark={true}
                onSubmit={this.handleSubmit}
                className={styles.confirmform}
                layout="horizontal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >


                <Row>
                  <Col>
                      Velg en sub under
                  </Col>
                  <Col>
                      {this.state.subscriptionList}
                  </Col>
                </Row>

                

                <br />
                <div className={styles.introsubtext}>
                  {" "}
                  Jeg samtykker til elektronisk kommunikasjon, samt godkjenner
                  Oss Norges{" "}
                  <a
                    target="_blank"
                    href="/kjopsvilkar"
                    className={styles.reservationLink}
                  >
                    kjøpsvilkår
                  </a>{" "}
                  og{" "}
                  <a
                    target="_blank"
                    href="/brukervilkar"
                    className={styles.reservationLink}
                  >
                    brukervilkår
                  </a>
                  .{" "}
                </div>

                <Form.Item wrapperCol={{ span: 24 }}>
                  {getFieldDecorator(FormFields.Consent, {
                    rules: [
                      {
                        required: true,
                        message: "Du må samtykke for å bestille abonnement!",
                      },
                    ],
                  })(
                    <label>
                      <span
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        Samtykke
                      </span>{" "}
                      <Checkbox name={FormFields.Consent} />
                    </label>
                  )}
                </Form.Item>

                <Form.Item colon={false} wrapperCol={{ span: 24 }}>
                  <Button
                    loading={this.state.buttonLoading}
                    className={styles.confirmbutton}
                    type="primary"
                    htmlType="submit"
                    size="large"
                  >
                    Gå til betaling
                  </Button>
                </Form.Item>
              </Form>
            </div>
            
          </div>
        )}
      />
    );
  }

  renderLoadingPage() {
    return (
      <div>
        <h1>Laster inn...</h1>
        <div className={styles.introtext}>
          <br />
          <br />
          <Spin indicator={antIcon} />
        </div>
      </div>
    );
  }

  renderThankYouPage() {
    window.scrollTo(0, 0);
    return (
      <div>
        <h1>Tusen takk! </h1>
        <div className={styles.introtext}>
          Gratulerer, du har nå bestilt deg en Oss-brikke. Utrolig kult at du
          ønsker å bli en del av oss. Du mottar straks en bekreftelse på mail.
          Forventet levering er 5-7 virkedager. Mens du venter på Oss-brikken er
          det et par praktiske ting du kan gjøre 😉
          <br />
          <br />
        </div>
        <div className={styles.subheader}>
          Åpne HAN-porten på strømmåleren din
        </div>
        <div className={styles.text}>
          Det første du må gjøre er å kontakte din nettleverandør (ikke
          strømleverandør), og be de om å åpne HAN-porten din. Hos de aller
          fleste nettleverandører kan dette gjøres via Min Side. Er ikke dette
          tilfellet hos deg, kontakt din nettleverandør via e-post eller
          telefon.
          <br />
          <br />
        </div>
        <div className={styles.subheader}>Last ned og logg inn i Oss-appen</div>
        <div className={styles.text}>
          Neste steg er å laste ned Oss-appen, som du finner her:
          <Row
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "center",
              maxWidth: 330,
            }}
            type="flex"
            justify="space-around"
            align="middle"
          >
            <Col xs={24} sm={12} style={{ marginTop: 24 }}>
              <AppStoreButton />
            </Col>
            <Col xs={24} sm={12} style={{ marginTop: 24 }}>
              <GooglePlayButton />
            </Col>
          </Row>
          <br />
          <br />
        </div>
        <div className={styles.text}>
          Du logger inn i appen ved å legge inn e-post adressen du brukte ved
          bestilling av Oss-brikken, og vi sender deg en påloggingskode på mail
          (vær obs på at denne kan komme i søppelpost). Så fort Oss-brikken er
          på plass hos deg vil du få tilgang til alle funksjoner i appen.{" "}
        </div>
      </div>
    );
  }

  renderErrorPage() {
    return (
      <div>
        <h1>Noe gikk galt</h1>
        <div className={styles.introtext}>
          {" "}
          {this.state.errorResponse.errorMessage}. Prøv på nytt, og ta kontakt
          med Oss på <a href="mailto:hei@oss.no">hei@oss.no</a> om problemet
          vedvarer.
          <br />
          <br />
        </div>
      </div>
    );
  }

  renderUnknownErrorPage() {
    return (
      <div>
        <h1>Ukjent Feil.</h1>
        <div className={styles.introtext}>Noe gikk galt.</div>
      </div>
    );
  }

  renderPage() {
    if (this.state.page == Page.Loading) {
      return this.renderLoadingPage();
    }
    if (this.state.page == Page.Purchase) {
      return this.renderPurchasePage();
    }
    if (this.state.page == Page.ThankYou) {
      return this.renderThankYouPage();
    }
    if (this.state.page == Page.Error) {
      return this.renderErrorPage();
    }
    if (this.state.page == Page.ErrorUnkown) {
      return this.renderUnknownErrorPage();
    }
  }

  render() {
    return (
      <SiteLayout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Oss | Abonnement</title>
          <script>
            {`
                    {
            window.intercomSettings = {
                app_id: "wpsqtjpa"
            };
            }`}
          </script>
          <script>
            {`
                    {
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/wpsqtjpa';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
            }`}
          </script>
        </Helmet>
        <div className={styles.smallcontainer}>{this.renderPage()}</div>
      </SiteLayout>
    );
  }
}

export default class Bekreft extends React.Component<any, any> {
  render() {
    const BekreftFormComponent = Form.create()(SubscriptionForm);
    return <BekreftFormComponent />;
  }
}
