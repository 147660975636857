import React from 'react'
import { Button } from "antd";

// @ts-ignore
import googleplayIcon from '../../../assets/googleplay.svg'

export default () => (
    <a href="https://play.google.com/store/apps/details?id=com.ossapp" target="_blank" rel="noopener">
        <Button style={{ padding: 0 }} type="default" size="large" {...{ "aria-label": "Last ned fra google play" }}>
            <img alt="Google play ikon" src={googleplayIcon} height="auto" width="130px" />
        </Button>
    </a>
)